import React from "react";
import './FourOThree.css'

const FourOThree = () => {
  return (
    <div className="f03body">
      <h1 className="f03h1">403</h1>
      <h3 className="f03h3">Access Forbidden</h3>
      <p className="f03p">You are not allowed to access this resource.</p>
    </div>
  );
};

export default FourOThree;
