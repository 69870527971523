import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/images/logo_dark.webp";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {" "}
      <div className="footer_top">
        <Col>
          <div classname="w-100" style={{ textAlign: "center" }}>
            <img src={logo} alt="Ingenium Education" className="purpleLogo" />
          </div>
          <p className="text-center mb-0">by</p>
          <p className="footer_address mt-1">GAPSPASM TECHNOLOGY SOLUTIONS PVT. LTD.</p>
          <p className="footer_address">
            B1/H3, Mohan Co-operative Industrial Area, <br />
            Mathura Road, New Delhi - 110044
          </p>
          <p className="footer_address">
            email us at info@ingeniumedu.com
          </p>
          <div className="social_icons">
            <a href="https://www.facebook.com/teamingenium.edu" target="_blank">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
            <a href="https://api.whatsapp.com/send?phone=8799755607">

              <ion-icon name="logo-whatsapp"></ion-icon>
            </a>
            <a
              href="https://www.instagram.com/accounts/login/?next=/ingenium_edu/"
              target="_blank"
            >
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
            <a
              href="https://www.linkedin.com/company/ingenium-education"
              target="_blank"
            >
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </div>
          <div className="d-flex justify-content-between w-25 mx-auto">
          <Link style={{ textDecoration: "none" }} to="/privacyPolicy">
            <p className="privacyPolicy">Privacy Policy</p>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/terms">
            <p className="privacyPolicy mr-3">Terms</p>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/refundpolicy">
            <p className="privacyPolicy mr-3">Refund Policy</p>
          </Link>
          </div>
        </Col>
      </div>
      <Row className="footer_copyright">
        <p className="copyright_text">
          <strong>Copyright © Ingenium | 2022</strong>
        </p>
      </Row>
    </>
  );
};

export default Footer;
