import React, { useState } from "react";
import styles from "./header.module.css";
import img from "../../assets/madam.png";
import CustomModal from "../Modal/Modal";

const NewHeader = () => {
    const [showModal, setShowModal] = useState(false);

    const hideModal = () =>setShowModal(false)

  return (
    <div className={`position-relative ${styles.banner}`}>
      <h1 className={styles.heading}>
        LAUNCH <span className={styles.highlight}>YOUR COURSE</span> <br />
        ON YOUR <span className={styles.ownApp}>OWN APP</span>
      </h1>
      <p className={styles.subtext}>
        Your Knowledge, Your Platform, Your Application – Go Live with Ingenium!
      </p>
      <div className={styles.buttons}>
        <button onClick={() => setShowModal(true)} className={styles.primaryButton}>Request a Demo</button>
        {/* <a href="#" className={styles.link}>
          View Pricing
        </a> */}
      </div>
      <div style={{maxWidth: '1100px'}} className="w-100 mx-auto text-center">
        <div className={`${styles.cloudText} ${styles.pos1}`}>
            <p  className="mb-0">0% Commission</p>
        </div>
        <div className={`${styles.cloudText} ${styles.pos2}`}>
            <p className="mb-0">No Revenue Sharing</p>
        </div>
        <img className={`${styles.heroImg} position-absolute`} style={{bottom: '0px', left: "50%"}} src={img} />
      </div>

      <CustomModal show={showModal} hideModal={hideModal} />
    </div>
  );
};

export default NewHeader;
